import { Component } from '@angular/core';

@Component({
  selector: 'docs-features',
  templateUrl: './docs-features.component.html',
  styleUrls: ['./../../docs.component.scss'],
})
export class DocsFeaturesComponent {
  zoomedPhoto: any = null;
}
